import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@mui/material';
import { CATEGORIES_STORAGE } from '../../../../constants';
import { addUserCategory, setUserCategories } from '../../../../redux/actions';
import { saveUserSearchConfig } from '../../../../api/user';


function SearchConfigConfirmSection({
   categories,
   exclusions,
   setUserCategories: setCategories,
}) {
   const { t } = useTranslation();
   const setUserCategoriesFromSessionStorage = () => {
      const storedCategories = JSON.parse(
         sessionStorage.getItem(CATEGORIES_STORAGE),
      );
      if (storedCategories) {
         setCategories(storedCategories);
      }
   };

   return (
      <ButtonGroup sx={{ p: 2 }} size='large' variant='outlined' aria-label='outlined button group'>
         <Button size='large' color='error'
            onClick={setUserCategoriesFromSessionStorage}>{t('menu.cancel', 'CANCEL')}</Button>
         <Button size='large' color='success'
            onClick={() => saveUserSearchConfig({ categories, exclusions })}>{t('menu.save', 'SAVE')}</Button>
      </ButtonGroup>
   );
}

export default connect(null, {
   addUserCategory,
   setUserCategories,
})(SearchConfigConfirmSection);