import { actionTypes } from '../actions/actionTypes';

const INITIAL_STATE = {
   isSignedIn: null,
   userId: null,
};

export const authReducer = (state = INITIAL_STATE, { type, payload }) => {
   switch (type) {
      case actionTypes.SIGN_IN:
         return { ...state, isSignedIn: true, userId: payload };
      case actionTypes.SIGN_OUT:
         return { ...state, isSignedIn: false, userId: null };
      default:
         return state;
   }
};
