import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from '../pages/home/HomePage';
import Navbar from './Navbar/Navbar';
import CartPage from '../pages/cart/CartPage';
import MenuPage from '../pages/menu/MenuPage';
import NotFound from '../pages/notFound/NotFound';
import SavingsPage from '../pages/savings/SavingsPage.tsx';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { grey,lightGreen,lime,blueGrey } from '@mui/material/colors';

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: blueGrey[400],
            },
            secondary: {
                main: '#11cb5f',
            },
        },
    });

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <Navbar />
                    {/* <Navbar /> */}
                    <div style={{"marginTop":"60px"}}></div>
                    <Switch>
                        <Route
                            path="/"
                            exact
                            render={(store) => <HomePage store={store} />}
                        />
                        <Route path="/list" exact component={CartPage} />
                        <Route path="/menu" exact component={MenuPage} />
                        {/*<Route path="/savings" exact component={SavingsPage} />*/}
                        <Route path="/" exact component={MenuPage} />
                        <Route>
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
