import React, { useState } from 'react';
import './ListItem.scss';
import { connect } from 'react-redux';
import { removeProductFromBasketAction } from '../../../../redux/actions';
import { ProductImage } from '../../../ProductImage/ProductImage';
import { setColourBasedOnDateDates } from '../../../../util/util';
import translate from '../../../../translation/Translator';
import AddExpenseDialog from '../../../../pages/cart/components/AddExpenseDialog';


function ListItem(props) {
    const [isSelected, setIsSelected] = useState(false);

    function makeItemSelected() {
        setIsSelected(!isSelected);
    }

    function renderPricingSection({ price, meta, volumePrice, volumeUnit }) {
        return (
            <div className='price_part'>
                <h3>
                    <span>{price ? `${price} ${translate('currency', 'dkk')}` : ''}</span>
                    {meta ? (
                        <>
                            <span className='oldPrice'>{meta.oldPrice}</span>
                            <span className='discountPercent'>
                                {meta.discountPercent
                                    ? `(${meta.discountPercent})`
                                    : ''}
                            </span>
                        </>
                    ) : (
                        ''
                    )}
                </h3>
                {volumePrice && volumeUnit ?
                    (<h3>{volumePrice} {translate('currency', 'dkk')}/{volumeUnit}{' '}</h3>) :
                    ('')}
            </div>
        );
    }

    const { data } = props;

    const { removeProductFromBasketAction: removeFromBasket, cartId } = props;
    return (<div>
        <div
            className={`shopping-item ${isSelected && 'item-selected'}`}
            onClick={makeItemSelected}>
            <ProductImage wrapperClass='' product={data} />

            {/* <AddExpenseDialog searchItem={data.searchItem} itemTitle={data.title} /> */}

            <h2 className='listItemTitle'>
                {data.title}
                {data.description && <h3>{data.description}</h3>}
            </h2>
            <h3 style={setColourBasedOnDateDates(data.validFrom, data.validTo)}>
                {data.validFrom} {translate('product.to', 'to')}{' '}
                {data.validTo ? data.validTo : 'XX'}
            </h3>

            {renderPricingSection(data)}

            <button
                className='remove_from_cart_btn'
                type='button'
                onClick={(e) => {
                    e.stopPropagation();
                    removeFromBasket(cartId, data);
                }}>X
            </button>
        </div>
    </div>
    );
}

const mapStateToProps = (state) => ({
    cartId: state.cartReducer.cartId,
});
export default connect(mapStateToProps, { removeProductFromBasketAction })(ListItem);
