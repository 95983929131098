export const actionTypes = {
   API_ERROR: 'API_ERROR',
   NO_APP_ERROR: 'NO_APP_ERROR',

   PRODUCT_SELECT: 'PRODUCT_SELECT',
   PRODUCT_DESELECT: 'PRODUCT_DESELECT',

   QUERY_UPDATE: 'QUERY_UPDATE',

   PRODUCT_LOAD_START: 'PRODUCT_LOAD_START',
   PRODUCT_LOAD_SUCCESS: 'PRODUCT_LOAD_SUCCESS',
   PRODUCT_LOAD_ERROR: 'PRODUCT_LOAD_ERROR',
   PRODUCT_UPDATE: 'PRODUCT_UPDATE',
   PRODUCT_ADD: 'PRODUCT_ADD',
   FILTER_PRODUCT_CATEGORY: 'FILTER_PRODUCT_CATEGORY',
   PRODUCT_ADD_CATEGORIES_WITH_PRODUCTS: 'PRODUCT_ADD_CATEGORIES_WITH_PRODUCTS',

   LOAD_USER_CART: 'LOAD_USER_CART',
   ADD_PRODUCT_TO_SHOPPING_BASKET: 'ADD_PRODUCT_TO_SHOPPING_BASKET',
   REMOVE_PRODUCT_FROM_SHOPPING_BASKET: 'REMOVE_PRODUCT_FROM_SHOPPING_BASKET',

   // ADD_PRODUCT_TO_SHOPPING_BASKET: 'ADD_PRODUCT_TO_SHOPPING_BASKET',
   // REMOVE_PRODUCT_FROM_SHOPPING_BASKET: 'REMOVE_PRODUCT_FROM_SHOPPING_BASKET',

   PRODUCT_SELECT_WHOLE_CATEGORY: 'PRODUCT_SELECT_WHOLE_CATEGORY',
   PRODUCT_DESELECT_WHOLE_CATEGORY: 'PRODUCT_DESELECT_WHOLE_CATEGORY',

   SIGN_IN: 'SIGN_IN',
   SIGN_OUT: 'SIGN_OUT',

   FETCH_USER_DATA: 'FETCH_USER_DATA',

   // CATEGORIES
   LOAD_USER_CATEGORIES: 'LOAD_USER_CATEGORIES',
   SET_USER_CATEGORIES: 'SET_USER_CATEGORIES',
   SAVE_USER_CATEGORIES: 'SAVE_USER_CATEGORIES',
   ADD_USER_CATEGORY: 'ADD_USER_CATEGORY',
   REMOVE_USER_CATEGORY: 'REMOVE_USER_CATEGORY',
   ADD_USER_CATEGORY_ITEM: 'ADD_USER_CATEGORY_ITEM',
   REMOVE_USER_CATEGORY_ITEM: 'REMOVE_USER_CATEGORY_ITEM',

   // CATEGORY NOTES
   LOAD_USER_NOTES: 'LOAD_USER_NOTES',
   UPDATE_CATEGORY_NOTES: 'UPDATE_CATEGORY_NOTES',
   ADD_NOTE_TO_CATEGORY: 'ADD_NOTE_TO_CATEGORY',
   DELETE_NOTE_FROM_CATEGORY: 'DELETE_NOTE_FROM_CATEGORY',

   SET_BINDING_REQUEST: 'SET_BINDING_REQUEST',
   DELETE_BINDING_REQUEST: 'DELETE_BINDING_REQUEST',
   APPROVE_BINDING_REQUEST: 'APPROVE_BINDING_REQUEST',
};
