import { actionTypes } from '../actions/actionTypes';
import { CATEGORIES_STORAGE } from '../../constants';
import { UserCategoryWithItems } from '../../types/UserCategoryWithItems';

export const userCategoriesReducer = (state:UserCategoryWithItems = {}, { type, payload }) => {
   let userCategories:UserCategoryWithItems;
   switch (type) {
      case actionTypes.LOAD_USER_CATEGORIES:
         sessionStorage.setItem(CATEGORIES_STORAGE, JSON.stringify(payload));
         return payload;
      case actionTypes.SET_USER_CATEGORIES:
         sessionStorage.setItem(CATEGORIES_STORAGE, JSON.stringify(payload));
         return payload;
      case actionTypes.REMOVE_USER_CATEGORY_ITEM:
         userCategories = { ...state };
         userCategories[payload.category] = userCategories[
            payload.category
         ].filter((el) => el !== payload.item);
         return userCategories;
      case actionTypes.ADD_USER_CATEGORY_ITEM:
         userCategories = { ...state };
         if (
            !userCategories[payload.category].includes(payload.item) &&
            payload.item.length > 1
         ) {
            userCategories[payload.category] = [
               ...userCategories[payload.category],
               payload.item.toLowerCase(),
            ];
         }
         return userCategories;
      case actionTypes.ADD_USER_CATEGORY:
         userCategories = { ...state };
         if (payload.length > 2) userCategories[payload.toLowerCase()] = [];
         return userCategories;
      case actionTypes.REMOVE_USER_CATEGORY:
         userCategories = { ...state };
         delete userCategories[payload];
         return userCategories;
      case actionTypes.ADD_NOTE_TO_CATEGORY:
         userCategories = { ...state };
         if (payload.length > 2) userCategories[payload.toLowerCase()] = [];
         return userCategories;
      default:
         return state;
   }
};

export type UserCategoriesReducerRootState = ReturnType<typeof userCategoriesReducer>;