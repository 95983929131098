import { connect, } from 'react-redux';
import { excludeShop, includeShop } from '../../../redux/reducers/searchShopsReducer';
import Button from '@mui/material/Button';

function ShopFilterButton({ shopName, excludedShops , includeShop, excludeShop }) {

   function toggleButtonActive() {
      if (isShopActive()) {
         excludeShop(shopName);
      } else {
         includeShop(shopName);
      }
   }

   function isShopActive() {
      if (excludedShops) {
         return excludedShops.includes(shopName);
      }
      return false;
   }

   return <Button
      variant={isShopActive() ? 'outlined' : 'contained'}
      // color={isShopActive() ? 'success' : 'error'}
      // style={{ backgroundColor: isShopActive() ? 'red' : 'green' }}
      onClick={() => toggleButtonActive()}
      type='button'>{shopName}
   </Button>;
}

export default connect(null, {
   includeShop,
   excludeShop,
})(ShopFilterButton);