import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import EnhancedTable from './table/MuiTable';
import { addProductPriceNotes, productPriceNotesSelector } from '../redux/savingsProductsReducer';
import { UserCategoriesReducerRootState } from '../../../redux/reducers/userCategoriesReducer';
import { CategoryPriceNotes } from '../../../types/CategoryPriceNotes';

function ProductPricesTableTabView({ addProductPriceNotes }) {
   const categories = useSelector(
      (state: UserCategoriesReducerRootState) => state.userCategoriesReducer
   );
   const categoryPriceNotes:CategoryPriceNotes = useSelector(productPriceNotesSelector);
   console.log('notes', categoryPriceNotes);
   const [activeGrouping, setActiveGrouping] = useState(null);
   const [activeItem, setActiveItem] = useState(null);
   const [groupings, setGroupings] = useState([]);

   console.log('groupings', groupings);
   useEffect(() => {
      if (groupings.length === 0) {
         setGroupings(Object.keys(categories));
      }
      const active = groupings[0];
      setActiveGrouping(active);
      setActiveItem((categories[active] && categories[active][0]) || '');
      return null;
   }, [categories]);
   const [activeTab, setActiveTab] = useState(0);

   const handleChange = (event, newValue) => {
      setActiveTab(newValue);
   };

   return (<>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={activeTab}
            variant='scrollable'
            scrollButtons='auto'
            onChange={handleChange}
            aria-label='basic tabs example'>
            {groupings && groupings.map(grouping => <Tab key={grouping} label={grouping} />)}
         </Tabs>
      </Box>

      {categoryPriceNotes[groupings[activeTab]] &&
                <EnhancedTable
                   onNewAdd={(product) => {
                      addProductPriceNotes({ ...product, grouping: groupings[activeTab] });
                   }}
                   data={categoryPriceNotes[groupings[activeTab]]} />
      }

   </>
   );
}

export default connect(null, { addProductPriceNotes })(ProductPricesTableTabView);
