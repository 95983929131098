import React from 'react';
import { connect } from 'react-redux';
import googleIcon from '../assets/google.svg';
import { fetchUserDataAction, googleSignIn, googleSignOut } from '../redux/actions/index';
import { GOOGLE_AUTH_INIT, TOKEN_STORAGE } from '../constants';
import { getUserToken } from '../api/user';
import Button from '@mui/material/Button';

class GoogleAuth extends React.Component {
    USER_STORAGE = 'user';

    componentDidMount() {
        const user = JSON.parse(this.getStorageUser());
        const { googleSignIn: signIn } = this.props;
        let thenFunc;
        if (user) {
            signIn(user.id);
            thenFunc = () => {
                this.auth = window.gapi.auth2.getAuthInstance();
                this.auth.isSignedIn.listen(this.onAuthChange);
            };
        } else {
            thenFunc = () => {
                this.auth = window.gapi.auth2.getAuthInstance();
                this.onAuthChange(this.auth.isSignedIn.get());
                this.auth.isSignedIn.listen(this.onAuthChange);
            };
        }

        if (window.gapi) {
            window.gapi.load('client:auth2', () =>
                window.gapi.client.init(GOOGLE_AUTH_INIT).then(thenFunc),
            );
        }
    }

    onAuthChange = async (isSignedIn) => {
        const {
            googleSignIn: signIn,
            googleSignOut: signOut,
            fetchUserDataAction: fetchUserData,
        } = this.props;

        if (isSignedIn) {
            const user = this.parseUserDataFromGoogle();
            signIn(user.id);
            await getUserToken(user);
            fetchUserData();
        } else {
            sessionStorage.removeItem(this.USER_STORAGE);
            sessionStorage.removeItem(TOKEN_STORAGE);
            signOut();
        }
    };

    getStorageUser() {
        return sessionStorage.getItem(this.USER_STORAGE);
    }

    onSignInClick = () => {
        this.auth.signIn();
    };

    onSignOutClick = () => {
        this.auth.signOut();
    };

    parseUserDataFromGoogle() {
        return {
            id: this.auth.currentUser.get().getId(),
            email: this.auth.currentUser.get().getBasicProfile().getEmail(),
        };
    }

    renderAuthButton() {
        const { isSignedIn } = this.props;
        if (isSignedIn === null) {
            return null;
        }
        if (isSignedIn) {
            return (
                <Button
                    size="small"
                    sx={{ color: 'white', display: 'block' }}
                    type='button'
                    className='btn-g-login'
                    onClick={this.onSignOutClick}
                >
                    Logout
                </Button>
            );
        }
        return (
            <Button
                size="small"
                sx={{ color: 'white', display: 'block' }}
                type='button'
                className='btn-g-login'
                onClick={this.onSignInClick}
            >
                <img src={googleIcon} alt='' />
            </Button>
        );
    }

    render() {
        return <>{this.renderAuthButton()}</>;
    }
}

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
});

export default connect(mapStateToProps, {
    googleSignIn,
    googleSignOut,
    fetchUserDataAction,
})(GoogleAuth);
