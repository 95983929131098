import { CategoryPriceNotes } from '../../../types/CategoryPriceNotes';

const LOAD_USER_PRODUCT_PRICE_NOTES = 'A';
const ADD_PRODUCT_PRODUCT_PRICE_NOTE = 'ADD_PRODUCT_PRODUCT_PRICE_NOTE';
// const REMOVE_PRODUCT_PRODUCT_PRICE_NOTE = 'REMOVE_PRODUCT_PRODUCT_PRICE_NOTE';

// Selector
export const productPriceNotesSelector = (state:SavingsProductsReducerRootState) => {
   return state.productPriceNotes.productPriceNotesReducer;
};


// Action Creators
export function loadProductPriceNotes(notes) {
   return {
      type: LOAD_USER_PRODUCT_PRICE_NOTES,
      payload: notes,
   };
}

export function addProductPriceNotes(productPriceNote) {
   return {
      type: ADD_PRODUCT_PRODUCT_PRICE_NOTE,
      payload: productPriceNote,
   };
}

// Reducer
const savingsProductsReducer = (state:CategoryPriceNotes = {}, { type, payload }) => {
   switch (type) {
      case LOAD_USER_PRODUCT_PRICE_NOTES:
         if (payload == null) {
            return state;
         }
         return payload;
      case ADD_PRODUCT_PRODUCT_PRICE_NOTE:
         const { grouping, item } = payload;

         const product = { ...payload };
         delete product['grouping'];
         delete product['item'];

         const result = { ...state };
         result[grouping] = result[grouping] || {};
         result[grouping][item] = result[grouping][item] || [];
         result[grouping][item] = [...result[grouping][item], product] || [product];
         return result;
      default:
         return state;
   }
};

export default savingsProductsReducer;

export type SavingsProductsReducerRootState = ReturnType<typeof savingsProductsReducer>;
