import React, { useEffect, useState } from 'react';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import './NewProductForm.scss';
import { useSelector } from 'react-redux';


function NewProductForm({onNewAdd}) {
   const { t } = useTranslation();
   const categories = useSelector((state) => state.userCategoriesReducer);
   const [activeGrouping, setActiveGrouping] = useState(null);
   const [activeItem, setActiveItem] = useState(null);
   const [groupings, setGroupings] = useState([]);
   const [newProductName, setProductName] = useState('');
   const [newProductRegularPrice, setNewProductRegularPrice] = useState('');
   const [newProductCheapestPrice, setNewProductCheapestPrice] = useState('');

   useEffect(() => {
      if (groupings.length === 0) {
         setGroupings(Object.keys(categories));
      }
      const active = groupings[0];
      setActiveGrouping(active);
      setActiveItem((categories[active] && categories[active][0]) || '');
      return null;
   }, [categories, groupings]);

   const handleNameChange = (event) => {
      setProductName(event.target.value);
   };

   const handleRegularPriceChange = (event) => {
      setNewProductRegularPrice(parseFloat(event.target.value));
   };

   const handleCheapestPriceChange = (event) => {
      setNewProductCheapestPrice(parseFloat(event.target.value));
   };

   const handleAddProduct = () => {
      const newProduct = {
         name: newProductName,
         regularPrice: parseFloat(newProductRegularPrice),
         cheapestPrice: parseFloat(newProductCheapestPrice),
         item:activeItem,
         purchasePrice: '',
      };

      onNewAdd(newProduct);
      setProductName('');
      setNewProductRegularPrice('');
      setNewProductCheapestPrice('');
   };

   function handleGroupingInputChange(e) {
      const newActiveGrouping = e.target.value;
      setActiveGrouping(newActiveGrouping);
      setActiveItem((categories[newActiveGrouping] && categories[newActiveGrouping][0]) || '');
   }

   return (
      <form className='newProductForm' onSubmit={(e) => e.preventDefault()}>

         <TextField
            className='newProductForm__input'
            label={t(
               'savings.productName',
               'Product Name',
            )}
            InputLabelProps={{
               shrink: true,
            }}
            value={newProductName}
            onChange={handleNameChange}
         />

         <FormControl className='newProductForm__input'>
            <InputLabel shrink>{t('savings.category', 'Item')}</InputLabel>
            <Select label={t('savings.category', 'Item')}
               value={(categories[activeGrouping] && activeItem) || ''}
               onChange={(e) => setActiveItem(e.target.value)}>
               {categories[activeGrouping] &&
                  categories[activeGrouping].map((option) => (
                     <MenuItem key={option} value={option}>
                        {option}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>

         <TextField
            className='newProductForm__input'
            label={t(
               'savings.cheapestPrice',
               'Cheapest Price',
            )}
            value={newProductCheapestPrice}
            onChange={handleCheapestPriceChange}
            inputProps={{ min: '0' }}
            type='number'
            InputLabelProps={{
               shrink: true,
            }}
         />
         <TextField
            className='newProductForm__input'
            label={t(
               'savings.regularPrice',
               'Regular Price',
            )}
            type='number'
            value={newProductRegularPrice}
            onChange={handleRegularPriceChange}
            InputLabelProps={{
               shrink: true,
            }}
            inputProps={{ min: '0' }}
         />

         <IconButton edge='end' onClick={handleAddProduct}>
            <AddIcon />
         </IconButton>
      </form>
   );
}

export default NewProductForm;
