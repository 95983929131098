import React, { useState } from 'react';
import {
   Box,
   Button,
   Container,
   IconButton,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TextField,
   Typography,
   Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function SavingsTracker() {
   const [products, setProducts] = useState([]);
   const [newProductName, setNewProductName] = useState('');
   const [newProductRegularPrice, setNewProductRegularPrice] = useState('');
   const [newProductCheapestPrice, setNewProductCheapestPrice] = useState('');

   const handleNewProductNameChange = (event) => {
      setNewProductName(event.target.value);
   };

   const handleNewProductRegularPriceChange = (event) => {
      const value:string = event.target.value;
      setNewProductRegularPrice(parseFloat(value).toString());
   };

   const handleNewProductCheapestPriceChange = (event) => {
      setNewProductCheapestPrice(parseFloat(event.target.value).toString());
   };

   const handleAddProduct = () => {
      const newProduct = {
         name: newProductName,
         regularPrice: parseFloat(newProductRegularPrice),
         cheapestPrice: parseFloat(newProductCheapestPrice),
         purchasePrice: '',
      };
      setProducts([newProduct, ...products]);
      setNewProductName('');
      setNewProductRegularPrice('');
      setNewProductCheapestPrice('');
   };

   const handleDeleteProduct = (index) => {
      const updatedProducts = [...products];
      updatedProducts.splice(index, 1);
      setProducts(updatedProducts);
   };

   const handleEditProductField = (event, index, field) => {
      const updatedProducts = [...products];
      updatedProducts[index][field] = event.target.value;
      setProducts(updatedProducts);
   };

   const handleSubmitPurchasePrice = () => {
      const updatedProducts = [...products];
      updatedProducts[0].purchasePrice = updatedProducts[0].cheapestPrice;
      setProducts(updatedProducts);
   };

   const calculateTotalPurchasePrice = () => {
      let totalPurchasePrice = 0;
      products.forEach((product) => {
         totalPurchasePrice += product.purchasePrice || 0;
      });
      return totalPurchasePrice;
   };

   const calculateTotalSavings = () => {
      let totalSavings = 0;
      products.forEach((product) => {
         totalSavings += product.regularPrice - (product.purchasePrice || 0);
      });
      return totalSavings;
   };

   return (
      <Container maxWidth="sm">
         <Box py={4}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Savings Tracker
            </Typography>
            <Box mb={2}>
               <Typography variant="h5" component="h2">
                   New Product
               </Typography>
               <Box display="flex" alignItems="center" mt={2}>
                  <TextField
                     fullWidth
                     label="Product Name"
                     value={newProductName}
                     onChange={handleNewProductNameChange}
                  />
                  <TextField
                     label="Regular Price"
                     type="number"
                     value={newProductRegularPrice}
                     onChange={handleNewProductRegularPriceChange}
                     inputProps={{ min: '0' }}
                  />
                  <TextField
                     label="Cheapest Price"
                     type="number"
                     value={newProductCheapestPrice}
                     onChange={handleNewProductCheapestPriceChange}
                     inputProps={{ min: '0' }}
                  />
                  <IconButton edge="end" onClick={handleAddProduct}>
                     <AddIcon />
                  </IconButton>
               </Box>
            </Box>
            <Box mb={2}>
               <Typography variant="h5" component="h2">
                   Products
               </Typography>
               <TableContainer component={Paper}>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>Product Namesdx</TableCell>
                           <TableCell>Regular Price</TableCell>
                           <TableCell>Cheapest Price</TableCell>
                           <TableCell>Purchase Price</TableCell>
                           <TableCell />
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {products.map((product, index) => (
                           <TableRow key={index}>
                              <TableCell>
                                 <TextField
                                    value={product.name}
                                    onChange={(event) =>
                                       handleEditProductField(event, index, 'name')
                                    }
                                    InputProps={{
                                       endAdornment: (
                                          <IconButton
                                             size="small"
                                             edge="end"
                                             onClick={() => handleDeleteProduct(index)}
                                          >
                                             <DeleteIcon fontSize="small" />
                                          </IconButton>
                                       ),
                                    }}
                                 />
                              </TableCell>
                              <TableCell>
                                 <TextField
                                    type="number"
                                    value={product.regularPrice}
                                    onChange={(event) =>
                                       handleEditProductField(event, index, 'regularPrice')
                                    }
                                    inputProps={{ min: '0' }}
                                    InputProps={{ readOnly: true }}
                                 />
                              </TableCell>
                              <TableCell>
                                 <TextField
                                    type="number"
                                    value={product.cheapestPrice}
                                    onChange={(event) =>
                                       handleEditProductField(event, index, 'cheapestPrice')
                                    }
                                    inputProps={{ min: '0' }}
                                    InputProps={{ readOnly: true }}
                                 />
                              </TableCell>
                              <TableCell>
                                 <TextField
                                    type="number"
                                    value={product.purchasePrice}
                                    onChange={(event) =>
                                       handleEditProductField(event, index, 'purchasePrice')
                                    }
                                    inputProps={{ min: '0' }}
                                    InputProps={{ readOnly: true }}
                                 />
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
               <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={handleSubmitPurchasePrice}
                  >
                      Submit Purchase Prices
                  </Button>
               </Box>
            </Box>
            <Box mt={4}>
               <Typography variant="h5" component="h2">
                   Summary
               </Typography>
               <TableContainer component={Paper}>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>Product Name</TableCell>
                           <TableCell>Purchase Price</TableCell>
                           <TableCell>Total Purchase Price</TableCell>
                           <TableCell>Total Savings</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {products.map((product, index) => (
                           <TableRow key={index}>
                              <TableCell>{product.name}</TableCell>
                              <TableCell>{product.purchasePrice}</TableCell>
                              <TableCell>{calculateTotalPurchasePrice()}</TableCell>
                              <TableCell>{calculateTotalSavings()}</TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Box>
         </Box>
      </Container>
   );
}

export default SavingsTracker;
