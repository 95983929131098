import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
   Typography,
} from '@mui/material';
import AddProductPricesContainer from './components/AddProductPricesContainer';
import * as React from 'react';
import translate from '../../translation/Translator';
import SavingsTracker from './components/SavingsTracker';

function SavingsPage({ isSignedIn }) {
   const [shouldRedirect, setShouldRedirect] = useState(false);
   useEffect(() => {
      if (isSignedIn !== undefined && isSignedIn === false) {
         setShouldRedirect(true);
      }
      return null;
   }, [isSignedIn]);

   return !shouldRedirect ? (

      <div className="SavingsPage">
         <h1>{translate('savings.savingsOverview', 'Savings overview')}</h1>
         <Typography variant="h4" component="h1" align="center" gutterBottom>
            {translate('savings.savingsTracker', 'Savings Tracker')}
         </Typography>
         <AddProductPricesContainer/>
         <SavingsTracker/>
         {/* <SavingsTracker2/> */}

      </div>
   ) : (
      <Redirect to="/" />
   );
}

const mapStateToProps = (state) => ({
   isSignedIn: state.authReducer.isSignedIn,
   userId: state.authReducer.userId,
});
export default connect(mapStateToProps)(SavingsPage);
