import React from 'react';
import './CategoryEditTabs.scss';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function SearchConfigEditableField(props) {
   const {
      className,
      disabled,
      label,
      index,
      categoryCount,
      onDelete,
   } = props;
   const { onClick } = props;
   return (
      <div className={`tab ${className}`}
         onClick={(e) => {
            // if delete btn is clicked, don't trigger onClick
            if (e.target.parentElement.getAttribute('data-testid') === 'DeleteIcon') {
               return;
            }
            onClick(index);
         }}>
         <button
            type='button'
            className='tab-btn'
            disabled={disabled}
            style={{ paddingBottom: 0 }}
         >
            {label}
         </button>
         {categoryCount != null ? <span className='tab-count'>{categoryCount}</span> : null}
         <span className='deleteBtn'>
            <IconButton sx={{ p: 0 }} onClick={() => onDelete(label)} aria-label='delete' size='large'>
               <DeleteIcon />
            </IconButton>
         </span>
      </div>
   );
}

export default SearchConfigEditableField;
