import React, { useState } from 'react';
import '../../commonStyles/InputWithButton.scss';

function InputWithBtn({ positionClass, placeholder, clicked }) {
   const [text, setText] = useState('');
   const onInputChange = (e) => {
      setText(e.target.value);
   };

   const onFormSubmit = (e) => {
      e.preventDefault();
      setText('');
   };

   return (
      <form
         id="search-form"
         className={`inputWithBtn ${positionClass}`}
         onSubmit={onFormSubmit}
      >
         <input
            id="ad"
            value={text}
            onChange={onInputChange}
            placeholder={placeholder}
            autoComplete="off"
         />
         <button
            className="categoryItemAddBtn"
            type="submit"
            onClick={() => clicked(text)}
         >
            Add
         </button>
      </form>
   );
}

export default InputWithBtn;
