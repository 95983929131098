// eslint-disable-next-line import/no-cycle
import { addProductsWithCategories, dataLoadError, dataLoadSuccess } from '../redux/actions';
import {  filterOutOfDateAndExclusionsSortedByPrice, transformDataByProductType } from '../util/util';
import { IS_PRODUCTION} from '../constants';
import {  TEST_PRODUCTS_RESPONSE } from '../testMocks';

// TODO CHECK CYCLE

export async function loadDataForProducts(products = [],excludedShops, dispatch) {
   const webSocketAddressUrl = IS_PRODUCTION ? 'wss://rabatt3r.info/api' : 'ws://localhost:8080/api';

   const socket = new WebSocket(webSocketAddressUrl);

   socket.addEventListener('open', () => {
      const message = {
         type: 'PRODUCT_FETCH',
         lang: sessionStorage.getItem('i18nextLng'),
         products,
         excludedShops
      };
      socket.send(JSON.stringify(message));
   });

   socket.addEventListener('message', event => {
      const message = JSON.parse(event.data);
      if (!message || JSON.stringify(message) === '{}') {
         dispatch(dataLoadSuccess({}));
      } else if(message.status === 'INITIAL') {
         dispatch(dataLoadSuccess(filterOutOfDateAndExclusionsSortedByPrice(message.data)));
      } else {
         dispatch(addProductsWithCategories(
            filterOutOfDateAndExclusionsSortedByPrice(message.data))
         );
      }
      return event.data;
   });

   socket.onerror = function(error) {
      if (!process.env.REACT_APP_DOCKERIZED) {
         dispatch(
            dataLoadSuccess(
               transformDataByProductType(TEST_PRODUCTS_RESPONSE, products),
            ),
         );
      } else {
         dispatch(dataLoadError(error));
      }
   };
}
