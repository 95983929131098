import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import LanguageDetector from 'i18next-browser-languagedetector';

function setLanguage() {
   const lang = window.location.href.includes('taupyk') ? 'lt' : 'en';
   sessionStorage.setItem('i18nextLng', lang);
}

setLanguage();
i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next) // passes i18n down to react-i18next
   .init({
      fallbackLng: 'en',
      detection: {
         order: ['sessionStorage'],
         caches: ['cookie'],
      },
      debug: false,
      // backend: {
      //    loadPath: '/locales/{{lng}}/translation.json',
      // },
      react: {
         useSuspense: false,
      },
   });

export default i18n;
