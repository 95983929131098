import { connect } from 'react-redux';
import './MenuPage.scss';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CartBindingSection from './components/cartBinding/CartBindingSection';
import SearchConfigView from './components/categoryEdit/SearchConfigView';

function MenuPage({ userId, isSignedIn }) {
   const { t } = useTranslation();
   const [shouldRedirect, setShouldRedirect] = useState(false);
   useEffect(() => {
      if (isSignedIn !== undefined && isSignedIn === false) {
         setShouldRedirect(true);
      }
      return null;
   }, [isSignedIn]);

   return !shouldRedirect ? (
      <div className="MenuPage">
         <h1>{t('menu.editSettings', 'Edit settings')}</h1>
         <h3>
            {t('menu.userId', 'User ID:')} {userId}
         </h3>
         <div>
            <h2>{t('menu.bindShoppingList', 'Bind shopping list')}</h2>
            <hr />
            <CartBindingSection />
            <h2>{t('menu.editCategories', 'Edit categories')}</h2>
            <hr />
            <SearchConfigView />
         </div>
      </div>
   ) : (
      <Redirect to="/" />
   );
}

const mapStateToProps = (state) => ({
   isSignedIn: state.authReducer.isSignedIn,
   userId: state.authReducer.userId,
});
export default connect(mapStateToProps)(MenuPage);
