import React from 'react';
import './Tabs.scss';
import Tab from './Tab/Tab';

class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: 0 };
    }

    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const { children } = this.props;
        const { activeTab } = this.state;

        return (
            <div className='tabs'>
                <ol className='tab-list'>
                    {children.map((child) => {
                        const { label, index, products, disabled } = child.props;
                        return (
                            <Tab
                                key={label}
                                label={label}
                                index={index}
                                products={products}
                                disabled={disabled}
                                className={index === activeTab ? 'active-tab' : ''}
                                onClick={this.onClickTabItem}
                            />
                        );
                    })}
                </ol>
                <div className='tab-content'>
                    {children.map((child) => {
                        if (child.props.index !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    }
}

export default Tabs;
