import { UserCategoryWithItems } from '../types/UserCategoryWithItems';

function resolveDateNumbers(date) {
   const numbers = date
      .split('-')
      .map((dateVal) => (dateVal.length === 1 ? 0 + dateVal : dateVal));

   if (numbers[0].length > 2) {// means year date first
      return numbers;
   }

   return numbers.reverse();
}

export function dateToNumber(date) {
   if (date instanceof Date) {
      let dateNumbers = date.toLocaleDateString('lt').split('-');
      dateNumbers = dateNumbers.map((dateVal) =>
         dateVal.length === 1 ? 0 + dateVal : dateVal,
      );

      return parseInt(dateNumbers.join(''), 10);
   }
   if (date instanceof String || typeof date === 'string') {
      const dateNumbers = resolveDateNumbers(date);
      return parseInt(dateNumbers.join(''), 10);
   }
   return null;
}

export function setColourBasedOnDateDates(fromDate, toDate) {
   const fromDateObj = dateToNumber(fromDate);
   const toDateObj = dateToNumber(toDate);
   const currentDate = dateToNumber(new Date());

   if (currentDate >= fromDateObj) {
      return currentDate <= toDateObj ? { color: 'green' } : { color: 'red' };
   }
   return { color: 'red' };
}

export function filterOutOfDateProducts(productList) {
   return productList.filter(
      (product) =>
         product.validTo != null &&
            dateToNumber(product.validTo) >= dateToNumber(new Date()),
   );
}

export function filterProductsByExclusions(productList, exclusions) {

   return productList.filter(
      product => {
         const anyMatch = exclusions &&
                exclusions.find((word) => product.title.toLowerCase().includes(word.toLowerCase()));
         return !anyMatch;
      },
   );
}

export function filterOutOfDateAndExclusionsSortedByPrice(productObject) {
   if (productObject) {
      const keys = Object.keys(productObject);

      return keys.reduce((initial, key) => {
         const productList = productObject[key];
         let filteredProductList;
         try {
            filteredProductList = filterOutOfDateProducts(productList);
         } catch (e) {
            return initial;
         }

         if (filteredProductList.length > 0) {
            return { ...initial, [key]: sortByPrice(filteredProductList) };
         }
         return initial;
      }, {});
   }
   return {};
}

export function sortByPrice(productList) {
   return productList.filter(p => p).sort((first, second) => first.price - second.price);
}

export function generateQuery(selectedProductsArray, queryString) {
   const query = queryString.split(',').concat(selectedProductsArray);
   return query.filter((el) => el !== '');
}

function getWordFromArrayIfWordContains(word, productTypes) {
   for (let i = 0; i < productTypes.length; i++) {
      if (word.toLowerCase().includes(productTypes[i].toLowerCase())) {
         return productTypes[i];
      }
   }
   return null;
}

export function transformDataByProductType(data, productTypes) {
   let productType;
   return data.reduce((initial, next) => {
      productType = getWordFromArrayIfWordContains(next.title, productTypes);
      if (productType) {
         // eslint-disable-next-line no-param-reassign
         initial[productType] = [...(initial[productType] || []), next];
         return initial;
      }
      return initial;
   }, {});
}

export function containsOnlyDigits(input) {
   const regex = /^\d+$/;
   return regex.test(input);
}


export function deduceGroupingFromCategoryName(categories: UserCategoryWithItems,
   categoryName: string): string | null {

   //find in categories where name has same categoryname
   for (const category of Object.keys(categories)) {
      const result = categories[category].find(item =>
         item.toLowerCase() === categoryName.toLowerCase());
      if (result) {
         return category;
      }
   }
   return null;
}