import React from 'react';
import './SearchPart.scss';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { fetchDataAction, updateDataAction, updateQueryAction } from '../../redux/actions';
import SearchOptionsPart from './SearchOptionsPart/SearchOptionsPart';
import { generateQuery } from '../../util/util';
import ShopFilterPart from './SearchOptionsPart/ShopFilterPart';
import { H2, H5, primaryColor } from '../CommonStyles';
import Button from '@mui/material/Button';
import translate from '../../translation/Translator';

class SearchPart extends React.Component {
    onInputChange = (e) => {
        const { updateQueryAction: updateAction } = this.props;
        updateAction(e.target.value);
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        const {
            selectedProducts,
            query,
            excludedShops,
            fetchDataAction: fetchData,
        } = this.props;
        let products = generateQuery(Object.values(selectedProducts).flat(), query);
        fetchData(products, excludedShops);
    };

    render() {
        const { query } = this.props;
        const { t } = this.props;
        return (
            <div className='SearchPart'>
                <ShopFilterPart />
                <SearchOptionsPart />
                <div className='inputContainer'>
                    <h3 style={H5}>{t("home.extraSearch","[Optional] Extra product search:")}</h3>
                    <input
                        value={query}
                        onChange={this.onInputChange}
                        placeholder={t(
                            'home.loadDataPlaceHolder',
                            'Extra search e.g.: cola, kylling, jord, ...',
                        )}
                    />
                </div>
                <Button onClick={this.onFormSubmit}
                    variant="contained"
                    className='submit-btn'
                    type='submit'
                    form='search-form'>
                    <Trans i18nKey='home.loadData'>Load data</Trans>
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedProducts: state.productSelectorReducer,
    excludedShops: state.searchShopsReducer.excludedShops,
    query: state.searchReducer,
});

export default connect(mapStateToProps, {
    fetchDataAction,
    updateQueryAction,
    updateDataAction,
})(withTranslation()(SearchPart));
