import { TOKEN_STORAGE } from '../constants';

export function getRequestConfigWithAuthToken(httpMethod) {
   const storedToken = JSON.parse(sessionStorage.getItem(TOKEN_STORAGE));
   const token = storedToken && storedToken.replace('Bearer', '');
   return {
      method: httpMethod,
      headers: {
         'x-access-token': token,
      },
   };
}
