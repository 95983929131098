import React, { useState} from 'react';
import './SearchOptionsPart.scss';
import { connect } from 'react-redux';
import { SHOPS } from '../../../constants';
import translate from '../../../translation/Translator';
import  { toggleAllShops} from '../../../redux/reducers/searchShopsReducer';
import FilterShopButton from './ShopFilterButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import { H2 } from '../../CommonStyles';
import Container from '@mui/material/Container';


const ShopFilterPart = function({
    excludedShops,
    toggleAllShops,
}) {
    const [isSwitchActive, setIsSwitchActive] = useState(true);

    return (
        <div className='shopFilter'>
            <h2 style={H2}>{translate('home.shopFilter', 'Shop filter:')}</h2>
            <div className='shopFilterBtnWrapper'>
                <Checkbox onChange={() => {
                    setIsSwitchActive(!isSwitchActive);
                    toggleAllShops(isSwitchActive);
                }}
                size={'large'}
                defaultChecked={isSwitchActive} />

                <ButtonGroup
                    sx={{flexWrap: 'wrap', justifyContent: 'center'}}
                    size='small' variant="outlined" aria-label="outlined button group">
                    {
                        SHOPS.map(shopName =>
                            <FilterShopButton
                                key={shopName}
                                excludedShops={excludedShops}
                                shopName={shopName} />)

                    }
                </ButtonGroup>
                {/* </Container> */}
            </div>

        </div>
    );
};

const mapStateToProps = (state) => ({
    excludedShops: state.searchShopsReducer.excludedShops,
});

export default connect(mapStateToProps, { toggleAllShops })(ShopFilterPart);
