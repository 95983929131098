import React from 'react';
import './ShoppingList.scss';
import ListItem from './ShoppingListItem/ListItem';

function ShoppingList(props) {
   const { shoppingItems } = props;
   return (
      <div className="shopping-list">
         {shoppingItems.map((item) => (
            <ListItem key={item._id} data={item} />
         ))}
      </div>
   );
}

export default ShoppingList;
