import { sendConsoleErrorLogToServer } from './api/log';
import { IS_PRODUCTION } from './constants';

let shouldSendErrorLogToServer = true;
let retryScheduled = false;
// eslint-disable-next-line no-console
const originalError = console.error;

// eslint-disable-next-line no-console
if (IS_PRODUCTION) {
   console.error = (args) => {
      executeMainFunctionOrFallback(
         () => sendConsoleErrorLogToServer(args),
         () => originalError(args),
      );

   };

   window.onerror = (message, source, line, column, error) => {
      executeMainFunctionOrFallback(
         () => sendConsoleErrorLogToServer(error.stack),
         () => console.error(error.stack),
      );

      return true;
   };

   window.addEventListener('unhandledrejection', (event) => {
      event.preventDefault();
      executeMainFunctionOrFallback(
         () => sendConsoleErrorLogToServer(event.reason.toString()),
         () => console.log(event.reason),
      );
   });

}

function tryToEnableGraylogLoggingWithDelay(delaySeconds) {
   setTimeout(() => {
      sendConsoleErrorLogToServer('Empty')
         .then(() => {
            shouldSendErrorLogToServer = true;
            retryScheduled = false;
         })
         .catch(() => {
            shouldSendErrorLogToServer = false;
            retryScheduled = false;
         });
      retryScheduled = true;
   }, delaySeconds);
}

function executeMainFunctionOrFallback(mainFunction, fallbackFunction) {
   if (shouldSendErrorLogToServer) {
      mainFunction()
         .catch(() => {
            fallbackFunction();
            tryToEnableGraylogLoggingWithDelay(10000);
         });
   } else {
      if (!retryScheduled) {
         tryToEnableGraylogLoggingWithDelay(10000);
      }
      fallbackFunction();
   }
}
