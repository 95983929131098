import React, { useState } from 'react';
import { FilledInput, FormControl, FormHelperText, IconButton, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function SubmitTextInput({ name, placeholder, clicked, validation }) {
    const [text, setText] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const onInputChange = (e) => {
        const currentText = e.target.value;
        setText(currentText);
        if(error === true) {
            const isValid = validation.isValid(currentText);
            if(isValid) {
                setError(false);
            }
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        let isError = false;
        if (validation && !validation.isValid(text)) {
            isError = true;
            setError(isError);
            setErrorMsg(validation.msg);
        }

        if (!isError) {
            clicked(text);
            setText('');
        }
    };

    return (
        <form id={`input${ name}`} className='submitTextInput'  onSubmit={onFormSubmit}>
            <FormControl fullWidth error={error} sx={{ m: 1 }} variant='filled'>
                <InputLabel>{placeholder}</InputLabel>
                <FilledInput onChange={onInputChange}
                    value={text}
                    endAdornment={
                        <IconButton type='submit'>
                            <AddIcon />
                        </IconButton>
                    }
                />
                {error && <FormHelperText id='component-error-text'>{errorMsg}</FormHelperText>}
            </FormControl>
        </form>
    );
}

export default SubmitTextInput;
