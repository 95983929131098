import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import SearchPart from '../../components/SearchPart/SearchPart';
import ProductSection from '../../components/ProductSection/ProductSection';

function HomePage() {
   const { store } = useContext(ReactReduxContext);
   return (
      <>
         <SearchPart />
         <ProductSection store={store} />
      </>
   );
}
export default HomePage;
