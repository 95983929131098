import { Link } from 'react-router-dom';
import './NotFound.scss';
import { useTranslation } from 'react-i18next';

function NotFound() {
   const { t } = useTranslation();

   return (
      <div className="notFoundPage">
         <p>
            {' '}
            {t('pageRequestedNotFound', 'Page you requested was not found')}
         </p>
         <Link to="/" style={{ textDecoration: 'none' }}>
            {t('goBack', 'Go back!')}
         </Link>
      </div>
   );
}

export default NotFound;
