import React from 'react';
import { connect } from 'react-redux';
import { deleteCartBindingRequest } from '../../../../redux/actions';

function BindedDetails({
   userId,
   deleteCartBindingRequest: deleteCartBinding,
}) {
   return (
      <div className="bindedDetails">
         <h3>
            You have a shared list with user id: <b>{userId}</b>
         </h3>
         <button type="button" onClick={() => deleteCartBinding(userId)}>
            Unshare list
         </button>
      </div>
   );
}

export default connect(null, { deleteCartBindingRequest })(BindedDetails);
