import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ProductPricesTableHead from './ProductPricesTableHead';
import TableToolbar from './TableToolbar';
import NewProductForm from '../NewProductForm';

function descendingComparator(a, b, orderBy) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

function getComparator(order, orderBy) {
   return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
         return order;
      }
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

const headCells = [
   {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Product Name',
   },
   {
      id: 'calories',
      numeric: true,
      disablePadding: false,
      label: 'Regular Price',
   },
   {
      id: 'fat',
      numeric: true,
      disablePadding: false,
      label: 'Cheapest Price',
   },
   {
      id: 'carbs',
      numeric: true,
      disablePadding: false,
      label: 'Purchase Price',
   },
   {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: '',
   },
];

export default function EnhancedTable({ data, onNewAdd }) {

   const rows = Object.keys(data);
   const [order, setOrder] = React.useState('asc');
   const [orderBy, setOrderBy] = React.useState('calories');
   const [selected, setSelected] = React.useState([]);
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(5);
   const dense = true;

   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };

   const handleSelectAllClick = (event) => {
      if (event.target.checked) {
         const newSelected = rows.map((n) => n.name);
         setSelected(newSelected);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const isSelected = (name) => selected.indexOf(name) !== -1;

   // Avoid a layout jump when reaching the last page with empty rows.
   const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

   const visibleRows = React.useMemo(
      () =>
         stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
         ),
      [order, orderBy, page, rowsPerPage],
   );

   return (
      <Box sx={{ width: '100%' }}>
         <Paper sx={{ width: '100%', mb: 2 }}>
            <TableToolbar numSelected={selected.length} />
            <TableContainer>
               <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby='tableTitle'
                  size={dense ? 'small' : 'medium'}
               >
                  <ProductPricesTableHead
                     headCells={headCells}
                     numSelected={selected.length}
                     order={order}
                     orderBy={orderBy}
                     onSelectAllClick={handleSelectAllClick}
                     onRequestSort={handleRequestSort}
                     rowCount={rows.length}
                  />
                  <TableBody>

                     {
                        Object.keys(data).map((key, index) => {
                           const productPriceNotes = data[key];
                           // const isItemSelected = isSelected(row.name);
                           const isItemSelected = false;
                           const labelId = `enhanced-table-checkbox-${index}`;
                           return (
                              <>
                                 <TableRow
                                    role='checkbox'
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={key}
                                    sx={{ cursor: 'pointer' }}>
                                    <TableCell colSpan={3}>
                                       {key}
                                    </TableCell>
                                 </TableRow>
                                 {
                                    productPriceNotes.map(row => {
                                       return (<>

                                          <TableRow
                                             hover
                                             onClick={(event) => handleClick(event, row.name)}
                                             role='checkbox'
                                             aria-checked={isItemSelected}
                                             tabIndex={-1}
                                             key={row.name}
                                             selected={isItemSelected}
                                             sx={{ cursor: 'pointer' }}
                                          >

                                             <TableCell padding='checkbox'>
                                                <Checkbox
                                                   color='primary'
                                                   checked={isItemSelected}
                                                   inputProps={{
                                                      'aria-labelledby': labelId,
                                                   }}
                                                />
                                             </TableCell>
                                             <TableCell
                                                component='th'
                                                id={labelId}
                                                scope='row'
                                                padding='none'
                                             >
                                                {row.name}
                                             </TableCell>
                                             <TableCell align='right'>{row.regularPrice}</TableCell>
                                             <TableCell align='right'>{row.cheapestPrice}</TableCell>
                                             <TableCell align='right'>Edit</TableCell>
                                          </TableRow>

                                       </>
                                       );
                                    })}

                              </>);
                        })}


                     <TableRow>
                        <TableCell colSpan={10} >
                           <NewProductForm onNewAdd={onNewAdd} />
                        </TableCell>
                     </TableRow>

                     {emptyRows > 0 && (
                        <TableRow
                           style={{
                              height: (dense ? 33 : 53) * emptyRows,
                           }}
                        >
                           <TableCell colSpan={6} />
                        </TableRow>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               component='div'
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>
      </Box>
   );
}