import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { addUserCategory, removeUserCategory } from '../../../../redux/actions';
import ItemsView from './ItemsView';
import SubmitTextInput from './SubmitTextInput';

function SearchConfigGroupingSection({
   data,
   activeTab,
   onItemClick,
   addUserCategory: addCategory,
   removeUserCategory: removeCategory
}) {
   const categoriesKeys = Object.keys(data);
   const { t } = useTranslation();

   return (
      <div className='alterSection'>
         <Typography sx={{mt:2}} variant="h5">
            {t('menu.searchItemsGrouping', 'Search items grouping')}
         </Typography>
         <SubmitTextInput
            name='Grouping'
            placeholder={t('menu.newGroupingExample', 'e.g Meat')}
            clicked={addCategory}
            validation={{
               msg: t(
                  'validation.inputIsTooShort',
                  'Input is too short',
               ),
               isValid: text => text.length > 2,
            }} />

         <ItemsView data={categoriesKeys}
            // category={categoryToRender}
            onDelete={removeCategory}
            onItemClick={i => onItemClick(i)}
            childData={data}
            showChildLength
            activeTab={activeTab} />
      </div>
   );
}

export default connect(null, {
   addUserCategory,
   removeUserCategory
})(SearchConfigGroupingSection);
