import React from 'react';
import './CartBindingSection.scss';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CartBindingDialog from './CartBindingDialog';
import BindedDetails from './BindedDetails';
import { sendCartBindingRequest } from '../../../../redux/actions';
import SubmitTextInput from '../categoryEdit/SubmitTextInput';
import { containsOnlyDigits } from '../../../../util/util';

function renderBindedInfo(bindingRequest) {
    return bindingRequest.isApproved ? (
        <BindedDetails userId={bindingRequest.userId} />
    ) : (
        <CartBindingDialog
            sent={bindingRequest.sent}
            userId={bindingRequest.userId}
        />
    );
}

function CartBindingSection(props) {
    const { bindingRequest, sendCartBindingRequest: sendBindingRequest } = props;
    const { t } = useTranslation();

    const shareCart = (otherUserId) => {
        sendBindingRequest(otherUserId);
    };

    function renderBindingPart() {
        return (
            <div>
                <p>
                    {t(
                        'menu.haveSameListWithOtherUser',
                        'Have a same list with other user',
                    )}
                </p>
                <div>
                    <SubmitTextInput
                        name='Binding'
                        placeholder={t('menu.otherUserId', 'Other user id')}
                        clicked={(userId) => shareCart(userId)}
                        validation={{
                            msg: t(
                                'validation.inputIsTooShort',
                                'Input must be digits',
                            ),
                            isValid: text => containsOnlyDigits(text),
                        }}
                    />
                </div>
            </div>
        );
    }

    return !bindingRequest || Object.keys(bindingRequest).length === 0 ? (
        <div className='cartBindingPart'>{renderBindingPart()}</div>
    ) : (
        <div className='cartBindingPart'>{renderBindedInfo(bindingRequest)}</div>
    );
}

const mapStateToProps = (state) => ({
    bindingRequest: state.bindingRequestReducer,
});
export default connect(mapStateToProps, {
    sendCartBindingRequest,
})(CartBindingSection);
