export const primaryColor = '#78909c';
export const secondaryColor = '#b0bec5';

export const H2 = {
    marginTop: "10px",
    marginBottom: "10px" ,
    fontWeight: "bolder",
    fontSize: "20px",
};

export const H3 = {
    marginTop: "7px",
    marginBottom: "7px" ,
    fontWeight: "bolder",
    fontSize: "18px",
};
export const H5 = {
    marginTop: "5px",
    marginBottom: "5px" ,
    fontWeight: "bolder",
    fontSize: "15px",
};
