import React from 'react';
import { connect } from 'react-redux';
import {
   approveCartBindingRequest,
   deleteCartBindingRequest,
} from '../../../../redux/actions';

function CartBindingDialog({
   sent,
   userId,
   deleteCartBindingRequest: deleteCartBinding,
   approveCartBindingRequest: approveRequest,
}) {
   return sent ? (
      <div>
         <h3>Sent request:</h3>
         <h4>To user with id: {userId}</h4>
         <h4>User not approved yet...</h4>
         <button onClick={() => deleteCartBinding(userId)} type="button">
            Cancel request
         </button>
      </div>
   ) : (
      <div>
         <h3>Received request:</h3>
         <h4>From user with id: {userId}</h4>
         <h4>Approve request ?</h4>
         <div>
            <button onClick={() => deleteCartBinding(userId)} type="button">
               Reject
            </button>
            <button onClick={() => approveRequest(userId)} type="button">
               Approve
            </button>
         </div>
      </div>
   );
}

export default connect(null, {
   deleteCartBindingRequest,
   approveCartBindingRequest,
})(CartBindingDialog);
