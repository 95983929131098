// eslint-disable-next-line no-undef
export const IS_PRODUCTION =process.env.NODE_ENV === "production" ;
export const MAX_NOTES_PER_CATEGORY = 3;
export const MAX_CHARS_PER_NOTE = 20;
export const TOKEN_STORAGE = 'jwtToken';
export const CATEGORIES_STORAGE = 'CATEGORIES';
export const CART_PRODUCTS = 'cart-products';
export const DK_SHOPS = ['Netto', 'Rema 1000','Coop-365', 'Lidl', 'Føtex', 'Kvickly','Meny','Spar','Bilka', 'Irma' ];
export const LT_SHOPS = ['Maxima', 'Norfa', 'Lidl', 'Iki', 'Rimi'];
export const CATEGORIES_AND_PRODUCTS = {
   meat: ['kylling', 'svinekød', 'bøf'],
   fish: ['laks', 'torsk', 'tun'],
   drinks: ['innocent', 'rigtig', 'god morgen', 'mælk', 'cola', 'rose'],
   food: ['æg', 'pasta', 'fraiche'],
   nuts: ['mandler', 'hassel', 'pistaci'],
   household: ['papir', 'fairy', 'vask', 'svamp', 'stegepande'],
   fruits: [
      'æbler',
      'blåbær',
      'bananer',
      'ananas',
      'mandarin',
      'clementin',
      'appelsin',
      'kirsebær',
      'blomme',
      'fersken',
      'figen',
      'granat',
      'hindbær',
      'melon',
      'vandmelon',
      'jordbær',
      'kiwi',
      'paærer',
      'nektarin',
      'pomelo',
      'vindruer',
      'litchi',
   ],
   candies: ['marabou', 'Änglamark øko chokolade'],
   vegetables: [
      'tomat',
      'agurk',
      'gulerod',
      'majs',
      'kartofler',
      'spinat',
      'avocado',
      'citron',
      'hvidløg',
      'blomkål',
      'broccoli',
   ],
};
export const LT_CATEGORIES_AND_PRODUCTS = {
   mėsa: ['vištien', 'kiaulien', 'jautien'],
   žuvis: ['lašiš', 'menk', 'tun'],
   gėrimai: ['arbata', 'pienas'],
   maistas: ['kiaušin', 'makaron', 'griet', 'varškė'],
   riešutai: ['pistac', 'migdol', 'žemės'],
   namai: ['tuolet', 'fairy', 'kempin', 'keptuv', 'plovim'],
   vaisiai: [
      'obuol',
      'šilauog',
      'banan',
      'ananas',
      'mandari',
      'apelsin',
      'brašk',
      'slyv',
      'fig',
      'granat',
      'trešn',
      'melon',
      'arbūz',
      'kivi',
      'persik',
      'nektarin',
      'pomelo',
      'vynuogės',
   ],
   saldainiai: ['pergal', 'saldain'],
   daržovės: [
      'pomidorai',
      'agurka',
      'morkos',
      'bulvės',
      'špinatai',
      'avokad',
      'citrin',
      'brokoliai',
   ],
};

export const GOOGLE_AUTH_INIT = {
   clientId:
        '157014852583-cod3d0bqts31r4qtciljl67rr7bodsv6.apps.googleusercontent.com',
   scope: 'email',
   redirect_uri: '/success',
};

export const SHOPS = sessionStorage.getItem('i18nextLng') === 'lt'
   ? LT_SHOPS
   : DK_SHOPS;
