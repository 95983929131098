import SearchConfigEditableField from './SearchConfigEditableField';

function ItemsView({
   data = [],
   onDelete,
   onItemClick,
   childData = [],
   showChildLength,
   activeTab,
}) {
   if (!data) {
      return null;
   }

   return <div className='tab-list' role='tabpanel'>
      {data.length === 0 ? null :
         data.map((el, index) => (
            <SearchConfigEditableField
               key={el}
               label={el}
               index={index}
               className={index === activeTab ? 'active-tab' : ''}
               onClick={() => {
                  if (onItemClick) {
                     onItemClick(index);
                  }
               }}
               categoryCount={showChildLength && childData[el] ? childData[el].length : null}
               onDelete={onDelete}
            />
         ))}
   </div>;
}


export default ItemsView;