import React from 'react';
import './Tab.scss';
import { connect } from 'react-redux';
import { deSelectProductsAction, removeUserCategory, selectProductsAction } from '../../../redux/actions';
import { primaryColor } from '../../CommonStyles';

class Tab extends React.Component {
    handleCheckboxClick = (event) => {
        const {
            label,
            products,
            selectProductsAction: selectProduct,
            deSelectProductsAction: deSelectProduct,
        } = this.props;
        if (event.target.checked) {
            selectProduct(label, products);
        } else {
            deSelectProduct(label, products);
        }
    };

    render() {
        const {
            isEditable,
            className,
            disabled,
            label,
            index,
            selectedProducts,
            products,
            removeUserCategory: removeCategory,
        } = this.props;
        const { onClick } = this.props;
        return (
            <div className={`tab ${className}`}>
                <button
                    type='button'
                    className='tab-btn'
                    disabled={disabled}
                    onClick={() => onClick(index)}
                >
                    {label}
                </button>
                {isEditable ? (
                    <button
                        className='categoryItemDeleteBtn'
                        type='button'
                        onClick={() => removeCategory(label)}
                    >
                        X
                    </button>
                ) : (
                    ''
                )}
                <span>
                    {selectedProducts ? selectedProducts.length : 0}/
                    {products.length}
                </span>
                {!isEditable ? (
                    <input
                        style={{accentColor: primaryColor}}
                        checked={
                            !!(
                                selectedProducts &&
                                selectedProducts.length === products.length
                            )
                        }
                        onChange={() => {
                            //empty
                        }}
                        label={label}
                        onClick={this.handleCheckboxClick}
                        type='checkbox'
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selectedProducts: state.productSelectorReducer[ownProps.label],
});

export default connect(mapStateToProps, {
    selectProductsAction,
    deSelectProductsAction,
    removeUserCategory,
})(Tab);
