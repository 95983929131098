import React from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GoogleAuth from '../GoogleAuth';
import GlobalErrorBoundary from '../../GlobalErrorBoundary';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import translate from '../../translation/Translator';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction='down' in={!trigger}>
            {children}
        </Slide>
    );
}

function Navbar({ isSignedIn }) {
    const { t } = useTranslation();

    return (
        <GlobalErrorBoundary fallback={<p>Something went wrong</p>}>
            <div className='navbar'>
                <HideOnScroll>
                    <AppBar>
                        <Container maxWidth='xl'>
                            <Toolbar variant='dense' disableGutters>
                                <Box
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Link to='/'>
                                        {translate('nav.home', 'home')}
                                    </Link>
                                    <Link to='/list'>
                                        {translate('nav.cart', 'cart')}
                                    </Link>
                                    {isSignedIn &&
                                        (<>
                                            <Link to='/savings'>
                                                {t('nav.savings', 'savings')}
                                            </Link>

                                            <Link to='/menu'>
                                                {t('nav.menu', 'menu')}
                                            </Link>
                                        </>)
                                    }
                                    <GoogleAuth />
                                </Box>

                            </Toolbar>
                        </Container>
                    </AppBar>
                </HideOnScroll>
            </div>
        </GlobalErrorBoundary>
    );
}

const mapStateToProps = (state) => ({
    isSignedIn: state.authReducer.isSignedIn,
});

export default connect(mapStateToProps)(Navbar);
