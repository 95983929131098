import React, { useState } from 'react';
import ImageWithMagnifier from './ImageWithMagnifier';

export function ProductImage(props) {
   const [activeChildIndex, setActiveChildIndex] = useState(0);
   const { product } = props;
   const { child_products: childProducts, image } = product;

   if (image) {
      return <ImageWithMagnifier url={image} />;
   }
   if (childProducts) {
      return childProducts.length === 1 ? (
         <ImageWithMagnifier url={childProducts[activeChildIndex]} />
      ) : (
         <div className="alternative_product_view">
            <ImageWithMagnifier url={childProducts[activeChildIndex].image} />
            <h2>{childProducts[activeChildIndex].title}</h2>
            <div className="child_product_images">
               {childProducts.map((p, index) => (
                  <img
                     key={p.title + product.title}
                     src={p.image}
                     onClick={(event) => {
                        event.stopPropagation();
                        setActiveChildIndex(index);
                     }}
                     alt={p.title}
                  />
               ))}
            </div>
         </div>
      );
   }

   return <ImageWithMagnifier url={product.image} />;
}
