import axios from 'axios';
import {
   API_URL,
} from './apiConstants';

export async function sendErrorLogToServer(errorLog, errorInfo) {
   const requestBody = {
      message: errorLog,
      info: errorInfo,
   };

   await axios.post(`${API_URL}/log`, requestBody);
}

export async function sendConsoleErrorLogToServer(errorStackTrace) {
   const requestBody = {
      message: errorStackTrace,
   };

   await axios.post(`${API_URL}/log`, requestBody);
}


