import * as React from "react";
import * as ReactDOM from "react-dom";
import './index.css';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import App from './components/App';
import reducers from './redux/reducers';
import './translation/i18n';
import './consoleErrorCaptor';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import reportWebVitals from './reportWebVitals';
import * as productPriceNotesReducer from './pages/savings';

declare global {
   interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
   }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
   combineReducers({ ...reducers,
      productPriceNotes: productPriceNotesReducer.reducer,
   }),
   composeEnhancers(applyMiddleware(thunk)),
);

document.addEventListener('touchmove', (event) => {
   // @ts-ignore
   if (event.scale !== 1) {
      event.preventDefault();
   }
}, { passive: false });

ReactDOM.render(
   <React.StrictMode>
      <GlobalErrorBoundary>
         <React.Suspense fallback=''>
            <Provider store={store}>
               <App store={store} />
            </Provider>
         </React.Suspense>
      </GlobalErrorBoundary>
   </React.StrictMode>,
   document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
