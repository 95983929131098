import React from 'react';
import { Container, Typography } from '@mui/material';
import './AddProductPricesView.scss';
import ProductPricesTableTabView from './ProductPricesTableTabView';
import translate from '../../../translation/Translator';

function AddProductPricesContainer() {
   return (
      <Container maxWidth='lg'>
         <div className='newProductSection'>
            <Typography variant='h5' component='h2'>
               {translate(
                  'savings.addNewProductPrices',
                  'Add new product prices',
               )}
            </Typography>

            <ProductPricesTableTabView />
         </div>
      </Container>);
}

export default AddProductPricesContainer;
