import { actionTypes } from '../actions/itemSearchExclusions/itemExclusionsActionTypes';

export const userItemExclusionsReducer = (state = {}, { type, payload }) => {
   // state e.g: {kylling: ['as','a']}
   switch (type) {
      case actionTypes.LOAD_USER_ITEM_SEARCH_EXCLUSIONS:
         if (payload == null) {
            return state;
         }
         return payload;
      case actionTypes.ADD_ITEM_SEARCH_EXCLUSION:
         const { category, exclusionToAdd } = payload;
         const categoryExclusions = [...(state[category] || [])];
         if (!categoryExclusions.includes(exclusionToAdd)) {
            return { ...state, [payload.category]: [...categoryExclusions,exclusionToAdd] };
         }
         return { ...state };
      case actionTypes.DELETE_ITEM_SEARCH_EXCLUSION:
         const { item, exclusionToDelete } = payload;
         const filteredExclusions = state[item].filter(
            i => i !== exclusionToDelete,
         );

         return { ...state, [payload.item]: filteredExclusions };
      default:
         return state;
   }
};
