import { SHOPS } from '../../constants';

const INCLUDE_SHOP_TO_FILTER = 'INCLUDE_SHOP_TO_FILTER';
const INCLUDE_ALL_SHOPS = 'INCLUDE_ALL_SHOPS';
const EXCLUDE_SHOP_FROM_FILTER = 'EXCLUDE_SHOP_FROM_FILTER';
const EXCLUDE_ALL_SHOPS = 'EXCLUDE_ALL_SHOPS';


// Selector
export const shopFilterSelector = (state: any) => {
    return state.searchShopsReducer.shops;
};


export function includeShop(shop: string) {
    return {
        type: INCLUDE_SHOP_TO_FILTER,
        payload: shop,
    };
}

export function toggleAllShops( isActive:boolean) {
    if (isActive) {
        return {
            type: INCLUDE_ALL_SHOPS,
            payload: SHOPS,
        };
    }

    return {
        type: EXCLUDE_ALL_SHOPS,
        payload: [],
    };
}

export function excludeShop(shop: string) {
    return {
        type: EXCLUDE_SHOP_FROM_FILTER,
        payload: shop,
    };
}

// Reducer
const searchShopsReducer = (state: { excludedShops: string[] } = { 'excludedShops': [] }, { type, payload }) => {

    switch (type) {
        case INCLUDE_SHOP_TO_FILTER:
            if (state.excludedShops.find(shopName => shopName === payload)) {
                return state;
            }
            return {excludedShops: [...state.excludedShops, payload]};
        case EXCLUDE_SHOP_FROM_FILTER:
            return { excludedShops: state.excludedShops.filter(shop => shop !== payload)};
        case INCLUDE_ALL_SHOPS:
            return { excludedShops: payload };
        case EXCLUDE_ALL_SHOPS:
            return { excludedShops: [] };
        default:
            return state;
    }
};

export default searchShopsReducer;

export type SearchShopsReducerRootState = ReturnType<typeof searchShopsReducer>;
